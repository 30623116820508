<template>
  <div class="guazhou" :class="locale !== 'zh-CN' ? 'guazhou-en' : ''">
    <div class="guazhou-header">
      <public-header></public-header>
      <div class="guazhou-title">
        <p class="spacing6">{{ $t("guazhouTitle") }}</p>
      </div>
      <el-carousel
        arrow="always"
        class="carousel banner"
        :class="locale !== 'zh-CN' ? 'carousel-en' : ''"
        height="750px"
      >
        <el-carousel-item
          v-for="(item, index) in carouselBannerList"
          :key="index"
        >
          <img :src="item.bannerPic" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 说明 -->
    <div class="explain center margin-top96">
      <p class="explain-content">{{ $t("guazhouExplainContent") }}</p>
      <ul class="explain-data">
        <li>
          <img src="../../assets/images/guazhou/data30.png" alt="data" />
          <p class="data-title">{{ $t("guazhouExplainData1Title") }}</p>
          <p class="data-explain">{{ $t("guazhouExplainData1Content") }}</p>
        </li>
        <li>
          <img src="../../assets/images/guazhou/data14.png" alt="data" />
          <p class="data-title">{{ $t("guazhouExplainData2Title") }}</p>
          <p class="data-explain">{{ $t("guazhouExplainData2Content") }}</p>
        </li>
        <li>
          <img src="../../assets/images/guazhou/data27.png" alt="data" />
          <p class="data-title">{{ $t("guazhouExplainData3Title") }}</p>
          <p class="data-explain">{{ $t("guazhouExplainData3Content") }}</p>
        </li>
        <li>
          <img src="../../assets/images/guazhou/data450.png" alt="data" />
          <p class="data-title">{{ $t("guazhouExplainData4Title") }}</p>
          <p class="data-explain">{{ $t("guazhouExplainData4Content") }}</p>
        </li>
      </ul>
      <div class="explain-detail">
        <ul class="detail">
          <li>{{ $t("guazhouExplainDetail1") }}</li>
          <li>{{ $t("guazhouExplainDetail2") }}</li>
          <li>{{ $t("guazhouExplainDetail3") }}</li>
          <li>{{ $t("guazhouExplainDetail4") }}</li>
          <li>{{ $t("guazhouExplainDetail5") }}</li>
        </ul>
        <div class="auth">
          <p class="auth-title" v-if="locale === 'zh-CN'">认证标准：</p>
          <img src="../../assets/images/guazhou/VER-logo.png" alt="auth" />
          <img src="../../assets/images/guazhou/CCB-logo.png" alt="auth" />
        </div>
      </div>
      <template v-if="locale === 'zh-CN'">
      <div class="auth-legend">
        <img src="../../assets/images/guazhou/data1.png" alt="auth" />
        <img src="../../assets/images/guazhou/data3.png" alt="auth" />
        <img src="../../assets/images/guazhou/data5.png" alt="auth" />
      </div>
      <div class="auth-legend margin-top50">
        <img src="../../assets/images/guazhou/data13.png" alt="auth" />
        <img src="../../assets/images/guazhou/data15.png" alt="auth" />
      </div>
    </template>
    <template v-else>
      <div class="auth-legend">
        <img src="../../assets/images/guazhou/data1-en.png" alt="auth" />
        <img src="../../assets/images/guazhou/data3-en.png" alt="auth" />
        <img src="../../assets/images/guazhou/data5-en.png" alt="auth" />
      </div>
      <div class="auth-legend margin-top50">
        <img src="../../assets/images/guazhou/data13-en.png" alt="auth" />
        <img src="../../assets/images/guazhou/data15-en.png" alt="auth" />
      </div>
    </template>
    </div>
    <!-- 措施 -->
    <div class="measure center margin-top96">
      <p class="guazhou-subTitle">{{ $t("measureTitle") }}</p>
      <ul class="measure-wrap">
        <li>
          <p class="measure-title">{{ $t("measureProject1Title") }}</p>
          <p
            class="measure-explain measure-explain-open"
          >
          <!-- :class="statusObj.status1 ? 'measure-explain-open' : ''" -->
            {{ $t("measureProject1Explain") }}
          </p>
          <!-- <p
            v-show="!statusObj.status1"
            class="click-status"
            @click="open('status1')"
          >
            {{ $t("measureStautsOpen") }}
          </p>
          <p
            v-show="statusObj.status1"
            class="click-status"
            @click="open('status1')"
          >
            {{ $t("measureStautsClose") }}
          </p> -->
        </li>
        <li>
          <p class="measure-title">{{ $t("measureProject2Title") }}</p>
          <p
            class="measure-explain measure-explain-open"
            
          >
          <!-- :class="statusObj.status2 ? 'measure-explain-open' : ''" -->
            {{ $t("measureProject2Explain") }}
          </p>
          <!-- <p
            v-show="!statusObj.status2"
            class="click-status"
            @click="open('status2')"
          >
            {{ $t("measureStautsOpen") }}
          </p>
          <p
            v-show="statusObj.status2"
            class="click-status"
            @click="open('status2')"
          >
            {{ $t("measureStautsClose") }}
          </p> -->
        </li>
        <li>
          <p class="measure-title">{{ $t("measureProject3Title") }}</p>
          <p
            class="measure-explain measure-explain-open"
            
          >
          <!-- :class="statusObj.status3 ? 'measure-explain-open' : ''" -->
            {{ $t("measureProject3Explain") }}
          </p>
          <!-- <p
            v-show="!statusObj.status3"
            class="click-status"
            @click="open('status3')"
          >
            {{ $t("measureStautsOpen") }}
          </p>
          <p
            v-show="statusObj.status3"
            class="click-status"
            @click="open('status3')"
          >
            {{ $t("measureStautsClose") }}
          </p> -->
        </li>
        <li>
          <p class="measure-title">{{ $t("measureProject4Title") }}</p>
          <p
            class="measure-explain measure-explain-open"
            
          >
          <!-- :class="statusObj.status4 ? 'measure-explain-open' : ''" -->
            {{ $t("measureProject4Explain") }}
          </p>
          <!-- <p
            v-show="!statusObj.status4"
            class="click-status"
            @click="open('status4')"
          >
            {{ $t("measureStautsOpen") }}
          </p>
          <p
            v-show="statusObj.status4"
            class="click-status"
            @click="open('status4')"
          >
            {{ $t("measureStautsClose") }}
          </p> -->
        </li>
      </ul>
    </div>
    <!-- 草种 -->
    <div class="grass-seeds center margin-top96">
      <p class="guazhou-subTitle">{{ $t("grassSeedsTitle") }}</p>
      <div class="grass-seeds-explain">
        <p>{{ $t("grassSeedsExplain1") }}</p>
        <p v-if="locale === 'zh-CN'">{{ $t("grassSeedsExplain11") }}</p>
        <p v-if="locale === 'zh-CN'">{{ $t("grassSeedsExplain12") }}</p>
        <p>{{ $t("grassSeedsExplain2") }}</p>
        <p v-if="locale === 'zh-CN'">{{ $t("grassSeedsExplain21") }}</p>
        <p v-if="locale === 'zh-CN'">{{ $t("grassSeedsExplain22") }}</p>
      </div>
      <el-carousel
        arrow="always"
        :autoplay="false"
        class="carousel"
        :height="
          locale === 'zh-CN'
            ? grassSeedsCarouselHeightZH[count]
            : grassSeedsCarouselHeightEN[count]
        "
        @change="carouselFun"
      >
        <el-carousel-item name="1">
          <div class="grass-seeds-carousel">
            <div class="grass-carousel-pic">
              <img
                src="../../assets/images/guazhou/grass-seeds-1.png"
                alt="grass-seeds"
              />
            </div>
            <div class="grass-carousel-detail">
              <p class="grass-carousel-detail-title">
                {{ $t("grassSeedsProject1Title") }}
                <span>{{ $t("grassSeedsProject1Title1") }}</span>
              </p>
              <p class="grass-carousel-detail-explain">
                {{ $t("grassSeedsProject1Explain") }}
              </p>
            </div>
          </div>
          <div class="grass-seeds-carousel">
            <div class="grass-carousel-pic">
              <img
                src="../../assets/images/guazhou/grass-seeds-2.png"
                alt="grass-seeds"
              />
            </div>
            <div class="grass-carousel-detail">
              <p class="grass-carousel-detail-title">
                {{ $t("grassSeedsProject2Title") }}
                <span>{{ $t("grassSeedsProject2Title1") }}</span>
              </p>
              <p class="grass-carousel-detail-explain">
                {{ $t("grassSeedsProject2Explain") }}
              </p>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item name="2">
          <div class="grass-seeds-carousel">
            <div class="grass-carousel-pic">
              <img
                src="../../assets/images/guazhou/grass-seeds-3.png"
                alt="grass-seeds"
              />
              <p class="grass-carousel-detail-origin">
                {{ $t("grassSeedsProject3ExplainOrigin") }}
              </p>
            </div>
            <div class="grass-carousel-detail">
              <p class="grass-carousel-detail-title">
                {{ $t("grassSeedsProject3Title") }}
                <span>{{ $t("grassSeedsProject3Title1") }}</span>
              </p>
              <p class="grass-carousel-detail-explain">
                {{ $t("grassSeedsProject3Explain") }}
              </p>
             
            </div>
          </div>
          <div class="grass-seeds-carousel">
            <div class="grass-carousel-pic">
              <img
                src="../../assets/images/guazhou/grass-seeds-4.png"
                alt="grass-seeds"
              />
            </div>
            <div class="grass-carousel-detail">
              <p class="grass-carousel-detail-title">
                {{ $t("grassSeedsProject4Title") }}
                <span>{{ $t("grassSeedsProject4Title1") }}</span>
              </p>
              <p class="grass-carousel-detail-explain">
                {{ $t("grassSeedsProject4Explain") }}
              </p>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 影响 -->
    <div class="influence center margin-top96">
      <p class="guazhou-subTitle">{{ $t("influenceTitle") }}</p>
      <p class="small-title">{{ $t("influenceSubTitle1") }}</p>
      <div class="video">
        <video 
          width="960px"
          height="552px"
          id="my-video"
          class="video-js"
          preload="auto"
       
          controls
          :controlBar="{
            progressControl: false,
          }"
          bigPlayButton
          data-setup="{}"
        >
          <source src="../../assets/video/influence.mp4" type="video/mp4" />
        </video>
        <!-- <video width="980px" height="552px" src="../../assets/video/influence.mp4"></video> -->
      </div>
      <p class="influence-explain">{{ $t('influenceExplain') }}</p>
      <p class="small-title">{{ $t("influenceSubTitle2") }}</p>
      <ul class="influence-project">
        <li>
          <div class="pic">
            <img
              src="../../assets/images/guazhou/ovis-darwini.png"
              alt="influence"
            />
          </div>
          <div class="influence-explain">
            <p class="explain-title">
              {{ $t("influenceProject1Title") }}
              <span>{{ $t("influenceProject1Title1") }}</span>
            </p>
            <p class="explain-sub-title">
              {{ $t("influenceProject1SubTitle1") }}
            </p>
            <p class="explain-sub-title">
              {{ $t("influenceProject1SubTitle2") }}
            </p>
            <p class="explain-content">{{ $t("influenceProject1Explain") }}</p>
          </div>
        </li>
        <li>
          <div class="pic">
            <img src="../../assets/images/guazhou/gazela.png" alt="influence" />
          </div>
          <div class="influence-explain">
            <p class="explain-title">
              {{ $t("influenceProject2Title") }}
              <span>{{ $t("influenceProject2Title1") }}</span>
            </p>
            <p class="explain-sub-title">
              {{ $t("influenceProject2SubTitle1") }}
            </p>
            <p class="explain-sub-title">
              {{ $t("influenceProject2SubTitle2") }}
            </p>
            <p class="explain-content">{{ $t("influenceProject2Explain") }}</p>
          </div>
        </li>
        <li>
          <div class="pic">
            <img
              src="../../assets/images/guazhou/panthear.png"
              alt="influence"
            />
          </div>
          <div class="influence-explain">
            <p class="explain-title">
              {{ $t("influenceProject3Title") }}
              <span>{{ $t("influenceProject3Title1") }}</span>
            </p>
            <p class="explain-sub-title">
              {{ $t("influenceProject3SubTitle1") }}
            </p>
            <p class="explain-sub-title">
              {{ $t("influenceProject3SubTitle2") }}
            </p>
            <p class="explain-content">{{ $t("influenceProject3Explain") }}</p>
          </div>
        </li>
      </ul>
    </div>
    <!-- 居民 -->
    <div class="resident center margin-top96">
      <p class="guazhou-subTitle">{{ $t("residentTitle") }}</p>
      <p class="resident-explain">{{ $t("residentExplain1") }}</p>
      <p class="resident-explain">{{ $t("residentExplain2") }}</p>
      <p v-if="locale === 'zh-CN'" class="resident-explain" style="margin-top: 0">{{ $t("residentExplain21") }}</p>
      <p class="resident-explain" style="text-wrap: balance; ">{{ $t("residentExplain3") }}{{ $t("residentExplain31") }}{{ $t("residentExplain32") }}</p>
      <!-- <p v-if="locale !== 'zh-CN'" class="resident-explain" style="margin-top: 0">{{ $t("residentExplain31") }}</p>
      <p v-if="locale !== 'zh-CN'" class="resident-explain" style="margin-top: 0">{{ $t("residentExplain32") }}</p> -->
      <img src="../../assets/images/guazhou/pic1.png" alt="resident" />
    </div>
    <!-- 下载 -->
    <div class="download center margin-top96">
      <p class="guazhou-subTitle">{{ $t("downloadTitle") }}</p>
      <ul>
        <li>
          <p>{{ $t("downloadProject1") }}</p>
          <div>
            <img src="../../assets/images/guazhou/look.png" alt="resident" @click="look('VCS4218_Guazhou_PD_ver3.0_项目描述文档')" />
            <img
              src="../../assets/images/guazhou/download.png"
              alt="resident"
              @click="download('VCS4218_Guazhou_PD_ver3.0_项目描述文档')"
            />
          </div>
        </li>
        <li>
          <p>{{ $t("downloadProject2") }}</p>
          <div>
            <img src="../../assets/images/guazhou/look.png" alt="resident" @click="look('ID_4218_Final%20Validation%20Report_Gansu_项目认证报告')" />
            <img
              src="../../assets/images/guazhou/download.png"
              alt="resident"
              @click="download('ID_4218_Final%20Validation%20Report_Gansu_项目认证报告')"
            />
          </div>
        </li>
        <li>
          <p>{{ $t("downloadProject3") }}</p>
          <div>
            <img src="../../assets/images/guazhou/look.png" alt="resident" />
            <img
              src="../../assets/images/guazhou/download.png"
              alt="resident"
            />
          </div>
        </li>
        <li>
          <p>{{ $t("downloadProject4") }}</p>
          <div>
            <img src="../../assets/images/guazhou/look.png" alt="resident" />
            <img
              src="../../assets/images/guazhou/download.png"
              alt="resident"
            />
          </div>
        </li>
      </ul>
    </div>
    <public-footer></public-footer>
  </div>
</template>
<script>
export default {
  name: "certificate",
  data() {
    return {
      locale: "",
      carouselBannerList: [
        { bannerPic: require("../../assets/images/guazhou/banner1.png") },
        { bannerPic: require("../../assets/images/guazhou/banner2.png") },
        { bannerPic: require("../../assets/images/guazhou/banner3.png") },
        { bannerPic: require("../../assets/images/guazhou/banner4.png") },
        { bannerPic: require("../../assets/images/guazhou/banner5.png") },
        { bannerPic: require("../../assets/images/guazhou/banner6.png") },
        { bannerPic: require("../../assets/images/guazhou/banner7.png") },
        { bannerPic: require("../../assets/images/guazhou/banner8.png") },
        { bannerPic: require("../../assets/images/guazhou/banner9.png") },
        { bannerPic: require("../../assets/images/guazhou/banner10.png") },
        { bannerPic: require("../../assets/images/guazhou/banner11.png") },
        { bannerPic: require("../../assets/images/guazhou/banner12.png") },
        { bannerPic: require("../../assets/images/guazhou/banner13.png") },
        { bannerPic: require("../../assets/images/guazhou/banner14.png") },
        { bannerPic: require("../../assets/images/guazhou/banner15.png") },
        { bannerPic: require("../../assets/images/guazhou/banner16.png") },
        { bannerPic: require("../../assets/images/guazhou/banner17.png") },
      ],
      statusObj: {
        status1: false,
        status2: false,
        status3: false,
        status4: false,
      },
      grassSeedsCarouselHeightZH: ["600px", "600px"],
      grassSeedsCarouselHeightEN: ["810px", "810px"],
      count: 0,
    };
  },
  created() {
    this.locale = localStorage.getItem("locale");
    this.locale = this.locale ? this.locale : "zh-CN";
  },

  methods: {
    open(str) {
      this.statusObj[str] = !this.statusObj[str];
    },
    carouselFun(ev) {
      this.count = ev;
    },
    download(str) {
      this.downloadFile(`./static/${str}.pdf`, str)
    },
    look(str) {
      this.openPdf(`./static/${str}.pdf`)
    },
    openPdf (url){
    // 创建隐藏的可下载链接
       window.open(url)
    },
    downloadFile (url,fileName) {   
      const x = new XMLHttpRequest();
      x.open('GET', url, true);
      x.responseType = 'blob';
      x.onload = function () {
        console.log(x.response)
        const url = window.URL.createObjectURL(x.response);
        const elink = document.createElement('a');
        elink.href = url;
        elink.target = '_self'; // 当前也 target打开新页面
        elink.setAttribute('download', fileName + '.pdf');
        elink.style.display = 'none';
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      };
      x.send();         
    },
  }
}
</script>
<style>
.guazhou {
  position: relative;
}
.guazhou-header {
  width: 100%;
  min-width: 1200px;
  position: relative;
  z-index: 9;
  height: 750px;
}
.guazhou .header {
  z-index: 9;
  position: relative;
}
.guazhou-title {
  margin-top: 283px;
  display: flex;
  justify-content: center;
  font-size: 68px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 68px;
  position: relative;
  top: 0;
  z-index: 9;
}
.guazhou-en .guazhou-title {
  font-size: 40px;
}
.guazhou-title p {
  color: #ffffff;
}
.guazhou .carousel {
  height: 100%;
  position: relative;
  border-radius: 0 ;
}
.banner {
  position: absolute !important;
  top: 0;
  width: 100%;
}
.banner .el-carousel__item {
  height: 750px !important;
}
.carousel .carousel-title,
.carousel .carousel-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 59px;
  text-align: center;
  font-style: normal;
}
.carousel .el-carousel__indicators {
  display: none;
}

.carousel .carousel-title {
  font-size: 42px;
}
.carousel .carousel-content {
  font-size: 36px;
}
.carousel .el-icon-arrow-left,
.carousel .el-icon-arrow-right {
  display: none !important;
  position: relative;
  z-index: 10;
}
.carousel .el-carousel__arrow--left,
.carousel .el-carousel__arrow--right {
  width: 28px;
  height: 50px;
  background-size: 100% 100% !important;
}
.carousel .el-carousel__arrow--left {
  background: url(../../assets/images/grassland/left.png) no-repeat;
}
.carousel .el-carousel__arrow--right {
  background: url(../../assets/images/grassland/right.png) no-repeat;
}

.carousel img {
  width: 100%;
  height: 100%;
}

.margin-top96 {
  margin-top: 96px;
}
.margin-top50 {
  margin-top: 50px;
}
.explain,
.measure,
.grass-seeds,
.influence,
.resident,
.download {
  box-shadow: 0px 0px 4px 0px rgba(161, 208, 107, 0.8);
  border-radius: 30px;
}
.explain {
  padding: 54px 34px;
}
.explain-content {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 40px;
  text-align: center;
  font-style: normal;
}
.explain-data {
  margin: 50px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.explain-data li {
  width: 200px;
  display: flex;
  flex-direction: column;
}
.explain-data li img {
  width: 72px;
  height: 82px;
  margin-bottom: 30px;
  margin-left: 64px;
}
.explain-data li p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
  text-align: center;
  font-style: normal;
}
.explain-data .data-title {
  font-size: 24px;
}
.explain-data .data-explain {
  font-size: 18px;
}
.explain-detail {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.explain-detail .detail {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  width: 438px;
  margin: 0 108px 0 63px;
}
.explain-detail .auth {
  display: flex;
  flex-direction: column;
}
.explain-detail .auth-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 37px;
  text-align: left;
  font-style: normal;
  margin-bottom: 15px;
}
.explain-detail .auth img:nth-child(2) {
  width: 208px;
  height: 52px;
  margin-bottom: 30px;
}

.explain-detail .auth img:last-child {
  width: 274px;
  height: 57px;
}
.guazhou-en .explain-detail .auth img:first-child {
  width: 222px;
  height: 57px;
  margin-bottom: 30px;
}
.guazhou-en .explain-detail .auth img:last-child {
  width: 284px;
  height: 57px;
}
.auth-legend {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.auth-legend img {
  width: 116px;
  height: 116px;
  margin-right: 50px;
}
.auth-legend img:last-child {
  margin: 0;
}

.measure {
  padding: 54px;
}
.guazhou-subTitle {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 42px;
  text-align: center;
  font-style: normal;
}
.measure-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
}
.measure-wrap li:nth-child(2n) {
  margin-left: 30px;
}
.measure-wrap li {
  width: 515px;
  padding-left: 42px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  line-height: 30px;
  text-align: left;
  font-style: normal;
  background-image: url(../../assets/images/guazhou/icon.png);
  background-size: 32px 59px;
  background-repeat: no-repeat;
  padding-top: 20px;
}
.measure-wrap li .measure-title {
  font-weight: 500;
}
.measure-wrap li .measure-explain {
  max-height: 60px;
  overflow: hidden;
}
.measure-wrap li .measure-explain-open {
  max-height: 500px;
  overflow: hidden;
}
.click-status {
  color: #0c8044;
  text-align: right;
  cursor: pointer;
}
.grass-seeds {
  padding: 54px 34px;
}
.grass-seeds-explain {
  margin: 50px 0;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 40px;
  font-style: normal;
}
.grass-seeds-explain p {
  text-align: center;
}
.grass-seeds-carousel {
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
}
.grass-seeds-carousel:last-child {
  margin: 0;
}
.grass-carousel-pic {
  margin-right: 50px;
  width: 250px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.guazhou .grass-carousel-pic img {
  width: 250px;
  height: 250px;
}



.grass-carousel-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #333333;
  line-height: 40px;
  text-align: left;
  font-style: normal;
  flex: 1;
}
.grass-carousel-detail .grass-carousel-detail-title {
  font-weight: 600;
  font-size: 24px;
}
.grass-carousel-detail .grass-carousel-detail-title span {
  margin-left: 10px;
  font-family: Italic;
}
.grass-carousel-detail .grass-carousel-detail-explain {
  font-weight: 400;
  font-size: 18px;
}
.grass-carousel-detail-origin {
  font-weight: 400;
  color: #999999;
  width: 500px;
  font-size: 20px;
  transform: translateX(-125px) scale(0.5);
  text-align: center;
}
.guazhou-en .grass-carousel-detail-origin {
  margin-top: 20px;
}
.grass-seeds .carousel .el-carousel__item {
  padding: 0 71px;
}

.influence {
  padding: 54px 34px;
}
.influence .small-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
  text-align: center;
  font-style: normal;
  margin: 30px 0;
}
.influence .video ,.influence .video-js {
  width: 960px;
  height: 552px;
  margin: 0 auto;
  border-radius: 30px;
  overflow: hidden;
}
.influence-explain {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  margin: 30px 0 50px;
}
.influence-project {
  padding: 0 70px;
}
.influence-project li {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.influence-project li .pic {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.influence-project li img {
  width: 250px;
  height: 250px;
  margin-right: 50px;
}
.influence-project .influence-explain {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 40px;
  font-style: normal;
  margin: 0;
}
.influence-project .explain-title {
  font-size: 24px;
  font-weight: 600;
}
.influence-project .explain-title span {
  margin-left: 10px;
  font-family: Italic;
}
.influence-project .explain-sub-title {
  font-size: 18px;
  font-weight: 600;
}
.influence-project .explain-content {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin: 0;
}
.resident {
  padding: 54px 34px;
}

.resident .resident-explain {
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
  line-height: 40px;
  text-align: center;
  font-style: normal;
  margin-top: 50px;
}
.resident img {
  width: 980px;
  height: 552px;
  margin: 30px auto 0;
  display: block;
}
.download {
  padding: 54px 84px;
  margin-bottom: 96px;
}
.download li {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.download li p {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 21px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
}
.download li img {
  width: 26px;
  height: 28px;
  margin-left: 20px;
  cursor: pointer;
}
.download li:nth-child(3), .download li:nth-child(4) {
  opacity: 0.6
}
</style>
